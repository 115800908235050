<template>
    <div>
        <h1>Preview Page</h1>
        <router-link to="/">Proceed to MSDAT</router-link>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  components: {
    RouterLink,
  },
};
</script>
